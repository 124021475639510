//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.btn-custom-sm {
    padding: 0.25rem 0.25rem;
    font-size: 0.75rem;
}
